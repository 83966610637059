import {uniq} from 'lodash'

import {smarterPost} from '@helpers/vuex/data-loading'
import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,
  state: {
    name: '',
    features: [],
    generatedAt: null,
    idColumn: null,
    originalZoneMapId: null
  },
  getters: {
    availableColumns (state) {
      return uniq(state.features.reduce((columns, feature) => columns.concat(Object.keys(feature.properties)), []))
    }
  },
  mutations: {
    ...makeSetters([
      'name',
      'generatedAt',
      'idColumn',
      'originalZoneMapId'
    ]),
    startImport (state, features) {
      state.name = ''
      state.features = features
      state.generatedAt = null
      state.idColumn = null
      state.originalZoneMapId = null
    },
    setName (state, name) {
      state.name = name
    }
  },
  actions: {
    startZoneMapImport ({commit}, featuresOrCollection) {
      commit('startImport', featuresOrCollection.features || featuresOrCollection)

      commit('fieldRecordSystem/setRightView', 'zoneMapImportSettings', {root: true})
    },
    async finishImport ({state, commit, rootGetters, rootState}) {
      const {name, generatedAt, idColumn, features, originalZoneMapId} = state

      const dto = {
        name,
        generatedAt,
        idColumn,
        originalZoneMapId,
        featureCollection: {
          type: 'FeatureCollection',
          features
        }
      }

      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
      await smarterPost(`/api/v2/entities/fields/${fieldId}/zone-maps/${harvestYear}`, dto, {
        id: 'frs.mapManagement.zoneMapImport'
      })
    }
  }
}
