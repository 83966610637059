import {uniq} from 'lodash'

import {smarterPost} from '@helpers/vuex/data-loading'
import {makeSetters} from '@helpers/vuex/mutations'

import translate from 'src/js/i18n/translate'

export default {
  namespaced: true,
  state: {
    soilMapTypes: [
      'pH',
      'soilGroup',
      'soilEstimation',
      'clayContent',
      'humusContent',
      'caORequiredNutrient'
    ],
    name: '',
    features: [],
    generatedAt: null,
    idColumn: null,
    mapType: null
  },
  getters: {
    availableColumns (state) {
      return uniq(state.features.reduce((columns, feature) => columns.concat(Object.keys(feature.properties)), []))
    }
  },
  mutations: {
    ...makeSetters([
      'name',
      'generatedAt',
      'idColumn',
      'mapType'
    ]),
    startImport (state, features) {
      state.name = ''
      state.features = features
      state.generatedAt = null
      state.idColumn = null
    }
  },
  actions: {
    setMapName ({commit, state, rootState, rootGetters}) {
      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      if (fieldId) {
        let fieldCode = rootState.fieldRecordSystem.data.field[fieldId].code
        fieldCode = fieldCode ? `${fieldCode}_` : ''
        const fieldName = rootGetters['fieldRecordSystem/fieldName']
        const harvestYear = rootGetters['fieldRecordSystem/harvestYear']

        if (!state.mapType) {
          commit('setName', `${fieldCode}${fieldName}_${harvestYear}`)
        } else {
          commit('setName', `${fieldCode}${fieldName}_${translate(`frs.mapManagement.soilMapImport.labels.mapType_${state.mapType}`)}_${harvestYear}`)
        }
      } else {
        commit('setName', '')
      }
    },
    startSoilMapImport ({commit, dispatch, state, rootState, rootGetters}, featuresOrCollection) {
      commit('startImport', featuresOrCollection.features || featuresOrCollection)

      commit('fieldRecordSystem/setRightView', 'soilMapImportSettings', {root: true})

      dispatch('setMapName')
    },
    async finishImport ({state, commit, rootGetters, rootState}) {
      const {name, generatedAt, idColumn, features, mapType} = state

      const dto = {
        name,
        generatedAt,
        idColumn,
        category: mapType,
        featureCollection: {
          type: 'FeatureCollection',
          features
        }
      }

      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
      await smarterPost(`/api/v2/entities/fields/${fieldId}/soil-maps/${harvestYear}`, dto, {
        id: 'frs.mapManagement.zoneMapImport'
      })
    }
  }
}
