import Vue from 'vue'
import axios from 'axios'
import {omit, fromPairs, pickBy} from 'lodash'
import parse from 'wellknown'

import {makeSetters} from '@helpers/vuex/mutations'
import {smarterGet} from '@helpers/vuex/data-loading'
import {toObject} from '@helpers/reducers'

import zoneMapImport from './zone-map-import'
import soilMapImport from './soil-map-import'
import zoneMapAdditionalValues from './zone-map-additional-values'

const getUniqueMaps = (fields, orgUnits, mapIdsByFieldId, mapLookup) => {
  const maps = {}

  for (const field of fields) {
    const mapIds = mapIdsByFieldId[field.id]

    if (!mapIds) continue

    mapIds.forEach(id => {
      maps[id] = mapLookup[id]
    })
  }

  const orgUnitIds = orgUnits.map(x => x.id)
  Object.keys(mapLookup)
    .map(id => mapLookup[id])
    .filter(map => !maps[map.id] && orgUnitIds.includes(map.orgUnitId))
    .forEach(map => {
      maps[map.id] = map
    })

  return Object.values(maps)
}

const makeMapLookup = (fields, orgUnits, mapIdsByFieldId, mapLookup) => {
  const seenMaps = {}
  const orgUnitIds = orgUnits.map(x => x.id)

  const lookup = fields.reduce((lookup, field) => {
    const mapIds = mapIdsByFieldId[field.id] || []

    mapIds.forEach(id => {
      seenMaps[id] = true
    })

    lookup[field.id] = mapIds.map(id => mapLookup[id])
    return lookup
  }, {})

  lookup['orphaned'] = Object.keys(mapLookup)
    .map(id => mapLookup[id])
    .filter(x => !seenMaps[x.id] && orgUnitIds.includes(x.orgUnitId))

  return lookup
}

const mergeMaps = (lookup, maps) => {
  lookup = {...lookup}

  for (const map of maps) {
    const existingMap = lookup[map.id] || {}

    lookup[map.id] = {...existingMap, ...map}
  }

  return lookup
}

const mergeMapIds = (lookup, fieldId, mapIds) => {
  return lookup && lookup[fieldId]
    ? [...new Set(mapIds.concat(lookup[fieldId]))]
    : mapIds
}

const geometryTypes = ['applicationMap', 'zoneMap', 'nutrientMap', 'soilMap', 'fieldBorder']

const getMapType = (state, id) => {
  for (const type of geometryTypes) {
    if (id in state.data[type].mapLookup) {
      return type
    }
  }
}

const defaults = () => ({
  data: {
    applicationMap: {
      mapIdsByFieldId: {},
      mapLookup: {}
    },
    soilMap: {
      mapIdsByFieldId: {},
      mapLookup: {}
    },
    zoneMap: {
      mapIdsByFieldId: {},
      mapLookup: {}
    },
    nutrientMap: {
      mapIdsByFieldId: {},
      mapLookup: {}
    },
    fieldBorder: {
      mapIdsByFieldId: {},
      mapLookup: {}
    }
  },
  mapIdsForDownload: [],
  ui: {
    detailsType: 'applicationMap',
    // multiple selection
    selectionMode: 'single', // delete, download
    selectionActive: false,
    selectionType: null, // applicationMap, zoneMap, nutrientMap, fieldBorder
    selection: null,
    mapId: null,
    // other ui stuff
    groupByFields: false,
    showFieldsWithoutMaps: false,
    zoneMapSorting: 'date', // name, date
    applicationMapSorting: 'module', // module, application, cultivation
    soilMapSorting: 'module', // module, application, cultivation
    newMapName: null,
    heterogenity: undefined,
    zoneMapPalette: 'agrosat',
    zoneMapPalettes: ['relative', 'agrosat', 'autoZoneMapDebug', 'pastel'],
    applicationMapPalette: 'agrosat',
    applicationMapPalettes: ['relative', 'agrosat'],
    showInactiveZoneMaps: true,

    toggledMapMenu: null,
    openSection: null,
    openSubSection: null,
    openSubSectionFieldId: null,

    moduleFilter: null,
    cropUsageFilter: null,
    dateTimeFilter: null,
    mapTypeFilter: null,
    fieldSearchResult: [],
    cropUsageByFieldFilter: [],
    filterIsLoading: false,

    scheduledRemovalLookup: {}
  }
})

export default {
  namespaced: true,
  state: defaults(),
  getters: {
    relevantOrgUnits (state, getters, rootState, rootGetters) {
      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      return fieldId ? [] : rootGetters['fieldRecordSystem/orgUnitsForSelectedOrgUnit']
    },
    relevantFields (state, getters, rootState, rootGetters) {
      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      const entityLookup = rootGetters['fieldRecordSystem/entityLookup']
      return fieldId
        ? [entityLookup[fieldId]]
        : rootGetters['fieldRecordSystem/fieldsForCurrentOrgUnit']
    },
    fieldIdsByMapId (state) {
      const lookup = {}

      for (const type of geometryTypes) {
        const mapIdsByFieldId = state.data[type].mapIdsByFieldId
        for (const fieldId in mapIdsByFieldId) {
          for (const mapId of mapIdsByFieldId[fieldId]) {
            if (!lookup[mapId]) {
              lookup[mapId] = []
            }
            lookup[mapId].push(fieldId)
          }
        }
      }

      return lookup
    },
    mapsByType (state, getters) {
      return geometryTypes.reduce((obj, type) => {
        obj[type] = getUniqueMaps(getters.relevantFields, getters.relevantOrgUnits, state.data[type].mapIdsByFieldId, state.data[type].mapLookup)
        return obj
      }, {})
    },
    mapsByFieldIdByType (state, getters) {
      return geometryTypes.reduce((obj, type) => {
        obj[type] = makeMapLookup(getters.relevantFields, getters.relevantOrgUnits, state.data[type].mapIdsByFieldId, state.data[type].mapLookup)
        return obj
      }, {})
    },
    mapLookup (state) {
      const lookup = {}

      for (const type of geometryTypes) {
        const subLookup = state.data[type].mapLookup
        for (const key in subLookup) {
          lookup[key] = subLookup[key]
        }
      }

      return lookup
    },
    selectedMapsByType (state) {
      const lookup = geometryTypes.reduce((lookup, type) => {
        lookup[type] = []
        return lookup
      }, {})

      state.mapIdsForDownload.forEach(id => {
        const type = getMapType(state, id)
        const map = state.data[type].mapLookup[id]
        lookup[type].push(map)
      })

      return lookup
    },
    productTypeLookup (state, getters, rootState, rootGetters) {
      const lookup = {}

      for (const product of rootGetters['masterData/productArray']) {
        lookup[product.id] = product.type
      }

      return lookup
    },
    applicationMapPalette (state) {
      return state.ui.applicationMapPalette
    },
    zoneMapPalette (state) {
      return state.ui.zoneMapPalette
    }
  },
  mutations: {
    ...makeSetters([
      'ui.selectionType',
      'ui.selection',
      'ui.showFieldsWithoutMaps',
      'ui.zoneMapSorting',
      'ui.applicationMapSorting',
      'ui.soilMapSorting',
      'ui.showZoneMapConverterModal',
      'ui.showApplicationMapConverterModal',
      'ui.newMapName',
      'ui.heterogenity',
      'ui.zoneMapPalette',
      'ui.applicationMapPalette',
      'ui.toggledMapMenu',
      'ui.showInactiveZoneMaps',
      'ui.moduleFilter',
      'ui.mapTypeFilter',
      'ui.cropUsageFilter',
      'ui.dateTimeFilter',
      'ui.fieldSearchResult',
      'ui.cropUsageByFieldFilter',
      'ui.filterIsLoading'
    ]),
    reset (state) {
      Object.assign(state, defaults())
    },
    setFilterValue (state, {options, type}) {
      const selectedValue = state.ui[`${type}Filter`]
      if (!options.includes(selectedValue)) {
        state.ui[`${type}Filter`] = null
      }
    },
    removeCropUsageFilter (state, filterOption) {
      const index = state.ui.cropUsageByFieldFilter.indexOf(filterOption)
      if (index > -1) {
        state.ui.cropUsageByFieldFilter.splice(index, 1)
      }
    },
    toggleSubSection (state, {fieldId = null, subSection}) {
      if (state.ui.openSubSection === subSection && state.ui.openSubSectionFieldId === fieldId) {
        state.ui.openSubSectionFieldId = null
        state.ui.openSubSection = null
      } else {
        state.ui.openSubSectionFieldId = fieldId
        state.ui.openSubSection = subSection
      }
    },
    setOpenSection (state, section) {
      state.ui.openSection = section
      state.ui.openSubSection = null
      state.ui.openSubSectionFieldId = null
    },
    setGroupByFields (state, value) {
      state.ui.groupByFields = value
      state.ui.openSubSection = null
      state.ui.openSubSectionFieldId = null
    },
    startRename (state, mapId) {
      const type = getMapType(state, mapId)

      state.ui.mapId = mapId
      state.ui.selectionType = type

      state.ui.newMapName = state.data[type].mapLookup[mapId].name
    },
    finishRename (state) {
      const mapId = state.ui.mapId

      const type = getMapType(state, mapId)
      state.data[type].mapLookup[mapId].name = state.ui.newMapName
      state.ui.newMapName = null
    },
    cancelRename (state) {
      state.ui.newMapName = null
    },
    startHeterogenityChange (state, mapId) {
      state.ui.mapId = mapId
      state.ui.heterogenity = state.data.zoneMap.mapLookup[mapId].heterogenity || null
    },
    finishHeterogenityChange (state) {
      const mapId = state.ui.mapId

      state.data.zoneMap.mapLookup[mapId].heterogenity = state.ui.heterogenity
      state.ui.heterogenity = undefined
    },
    cancelHeterogenityChange (state) {
      state.ui.heterogenity = undefined
    },
    startBatchDownload (state) {
      state.ui.selectionActive = true
      state.ui.selectionMode = 'download'
    },
    finishBatchOperation (state) {
      state.ui.selectionActive = false
      state.ui.selectionMode = 'single'
      // NOTE necessary to work around edge cases like selecting multiple map types during batchc delete and then switching to batch download
      state.ui.selectionType = null
      state.ui.selection = null
      state.mapIdsForDownload = []
    },
    clearSelection (state) {
      state.ui.selectionType = null
      state.ui.selection = null
      state.mapIdsForDownload = []
    },
    toggleCheckbox (state, {id, type}) {
      const index = state.mapIdsForDownload.indexOf(id)

      if (index > -1) {
        state.mapIdsForDownload.splice(index, 1)
        if (state.mapIdsForDownload.length === 0) {
          state.ui.selectionType = null
        }
      } else {
        state.mapIdsForDownload.push(id)
        state.ui.selectionType = type
      }
    },
    toggleSelection (state, {id, type}) {
      if (state.ui.selection && state.ui.selection.id === id) {
        state.ui.selection = null
      } else {
        state.ui.selection = {id, type}
      }
    },
    selectMultiple (state, {ids, type}) {
      if (state.ui.selectionMode === 'download' && state.ui.selectionType && type !== state.ui.selectionType) {
        throw new Error(`invalid selection: cannot select maps of type '${type}' when current selection is of type '${state.ui.selectionType}'`)
      }

      state.mapIdsForDownload = [...ids]
      state.ui.selectionType = type
    },
    deselectMultiple (state, {ids, type}) {
      if (state.ui.selectionMode === 'download' && state.ui.selectionType && type !== state.ui.selectionType) {
        throw new Error(`invalid selection: cannot deselect maps of type '${type}' when current selection is of type '${state.ui.selectionType}'`)
      }

      state.mapIdsForDownload = state.mapIdsForDownload.filter(id => !ids.includes(id))
      if (!state.mapIdsForDownload.length) {
        state.ui.selectionType = null
      }
    },
    updateInitialMapDataForField (state, {fieldId, maps, type}) {
      Vue.set(state.data[type].mapIdsByFieldId, fieldId, mergeMapIds(state.data[type].mapIdsByFieldId, fieldId, maps.map(x => x.id)))
      state.data[type].mapLookup = mergeMaps(state.data[type].mapLookup, maps)
    },
    updateInitialMapDataForOrgUnit (state, {maps, mapIdsByFieldId, type}) {
      Object.keys(mapIdsByFieldId).map(fieldId => {
        const mergedIds = mergeMapIds(state.data[type].mapIdsByFieldId, fieldId, mapIdsByFieldId[fieldId])
        Vue.set(state.data[type].mapIdsByFieldId, fieldId, mergedIds)
      })

      state.data[type].mapLookup = mergeMaps(state.data[type].mapLookup, maps)
    },
    updateMapDetails (state, {details, type}) {
      const lookup = state.data[type].mapLookup
      if (!lookup[details.id]) {
        throw new Error('cannot set details for map where metadata has not been set yet')
      }

      lookup[details.id] = {...lookup[details.id], ...details}
    },
    scheduleRemoval (state, mapIds) {
      const timestamp = Date.now() + 10000
      const newEntries = fromPairs(mapIds.map(id => [id, timestamp]))

      state.ui.scheduledRemovalLookup = Object.assign({}, state.ui.scheduledRemovalLookup, newEntries)
    },
    unscheduleRemoval (state, mapIds) {
      state.ui.scheduledRemovalLookup = omit(state.ui.scheduledRemovalLookup, mapIds)
    },
    removeMaps (state, {mapIds, fieldIdsByMapId}) {
      mapIds.forEach(id => {
        const type = getMapType(state, id)
        const {mapIdsByFieldId, mapLookup} = state.data[type]

        const fieldIds = fieldIdsByMapId[id]
        if (!fieldIds) {
          Vue.delete(mapLookup, id)
        } else {
          fieldIds.forEach(fieldId => {
            const index = mapIdsByFieldId[fieldId].indexOf(id)
            if (index >= 0) {
              mapIdsByFieldId[fieldId].splice(index, 1)
            }

            Vue.delete(mapLookup, id)
          })
        }

        state.ui.selection = null // state.ui.selection.filter(x => x !== id)
      })

      state.ui.scheduledRemovalLookup = omit(state.ui.scheduledRemovalLookup, mapIds)
    },
    setZoneMapActivation (state, {zoneMapId, isActive, isDemo}) {
      state.data.zoneMap.mapLookup[zoneMapId].active = isActive
      state.data.zoneMap.mapLookup[zoneMapId].isDemo = isDemo
    }
  },
  actions: {
    async loadAllMaps ({dispatch, commit, rootGetters}) {
      const currentFieldId = rootGetters['fieldRecordSystem/fieldId']
      const orgUnitId = rootGetters['fieldRecordSystem/orgUnitId']

      await dispatch('fieldRecordSystem/loadFieldGeometriesForCurrentOrgUnit', null, {root: true})

      if (currentFieldId) {
        return Promise.all([
          dispatch('loadAvailableZoneMaps', currentFieldId),
          dispatch('loadSoilMapsForField', currentFieldId),
          dispatch('loadNutrientMaps', currentFieldId),
          dispatch('loadAvailableApplicationMapsForField', currentFieldId),
          dispatch('loadGeometryForField', currentFieldId),
          dispatch('loadInterpolationMapsForField', currentFieldId)
        ])
        .then(() => {
          commit('clearSelection')
        })
      } else {
        return Promise.all([
          dispatch('loadAvailableZoneMapInfosForOrgUnitId', orgUnitId),
          dispatch('loadSoilMapsForOrgUnitId', orgUnitId),
          dispatch('loadAvailableNutrientMapInfosForOrgUnitId', orgUnitId),
          dispatch('loadAvailableApplicationMapsForOrgUnit', orgUnitId),
          dispatch('loadGeometriesForOrgUnit', orgUnitId),
          dispatch('loadInterpolationMapsByOrgUnitId', orgUnitId)
        ])
        .then(() => {
          commit('clearSelection')
        })
      }
    },
    async loadAvailableZoneMapInfosForOrgUnitId ({commit, rootState}, orgUnitId) {
      if (!orgUnitId) {
        console.error('try to call loadAvailableZoneMapInfosForOrgUnitId, but orgUnitId is null')
      }
      await smarterGet('/api/v2/entities/orgUnit/{orgUnitId}/zone-map-infos/{harvestYear}/by-field-id', {
        id: 'zoneMapInfos.forOrgUnit',
        expiry: 60,
        inputs: {
          orgUnitId: () => orgUnitId,
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
        },
        onResult ({zoneMaps: maps, zoneMapIdsByFieldId: mapIdsByFieldId}) {
          commit('updateInitialMapDataForOrgUnit', {maps, mapIdsByFieldId, type: 'zoneMap'})
        }
      })
    },
    async loadAvailableNutrientMapInfosForOrgUnitId ({commit, rootState}, orgUnitId) {
      const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
      try {
        await smarterGet(
          '/api/v2/entities/orgUnit/{orgUnitId}/nutrient-map-infos/by-field-id/{harvestYear}',
          {
            id: 'nutrientMaps.forOrgUnit',
            expiry: 60,
            inputs: {
              orgUnitId: () => orgUnitId,
              harvestYear: () => harvestYear
            },
            onResult ({nutrientMaps, nutrientMapIdsByFieldId}) {
              commit('updateInitialMapDataForOrgUnit', {maps: nutrientMaps, mapIdsByFieldId: nutrientMapIdsByFieldId, type: 'nutrientMap'})
            }
          })
      } catch (error) {
        if (error.response && error.response.status === 403) {
          commit('dataLoading/invalidate', 'nutrientMaps.forOrgUnit', {root: true})
          commit('updateInitialMapDataForOrgUnit', {maps: [], mapIdsByFieldId: {}, type: 'nutrientMap'})
        } else {
          throw error
        }
      }
    },
    async loadAvailableApplicationMapsForOrgUnit ({commit, rootState}, orgUnitId) {
      await smarterGet('/api/v2/map-management/orgunits/{orgUnitId}/application-map-infos/{harvestYear}', {
        id: 'applicationMapInfos.forOrgUnit',
        expiry: 60,
        inputs: {
          orgUnitId: () => orgUnitId,
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
        },
        onResult ({applicationMaps: maps, applicationMapIdsByFieldId: mapIdsByFieldId}) {
          commit('updateInitialMapDataForOrgUnit', {maps, mapIdsByFieldId, type: 'applicationMap'})
        }
      })
    },
    async loadSoilMapsForOrgUnitId ({commit, rootState}, orgUnitId) {
      await smarterGet('/api/v2/map-management/orgunits/{orgUnitId}/soil-map-infos/{harvestYear}', {
        id: 'soilMapInfos.forOrgUnit',
        expiry: 0,
        inputs: {
          orgUnitId: () => orgUnitId,
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
        },
        onResult ({soilMaps: maps, soilMapIdsByFieldId: mapIdsByFieldId}) {
          commit('updateInitialMapDataForOrgUnit', {maps, mapIdsByFieldId, type: 'soilMap'})
        }
      })
    },
    loadGeometriesForOrgUnit ({commit, rootGetters}) {
      const fieldIdLookup = rootGetters['fieldRecordSystem/fieldWktsFromOrgUnit'].map(field => ({
        [field.id]: [field.id]
      })).reduce(toObject, {})

      const maps = rootGetters['fieldRecordSystem/fieldWktsFromOrgUnit'].map(field => ({
        ...field,
        feature: {
          type: 'Feature',
          geometry: parse(field.wkt),
          properties: {
            id: field.id
          }
        }
      }))

      commit('updateInitialMapDataForOrgUnit', {maps, mapIdsByFieldId: fieldIdLookup, type: 'fieldBorder'})
    },
    async loadAvailableZoneMaps ({commit, rootState}, fieldId) {
      await smarterGet('/api/v2/entities/fields/{fieldId}/zone-maps/{harvestYear}', {
        id: 'zoneMaps.forField',
        expiry: 60,
        inputs: {
          fieldId: () => fieldId,
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
        },
        onResult (maps) {
          commit('updateInitialMapDataForField', {fieldId, maps, type: 'zoneMap'})
        }
      })
    },
    async loadNutrientMaps ({commit, rootState}, fieldId) {
      const harvestYear = rootState.fieldRecordSystem.userSettings.harvestYear
      try {
        await smarterGet('/api/v2/entities/fields/{fieldId}/nutrient-maps/{harvestYear}',
          {
            id: 'nutrientMaps.forField',
            expiry: 60,
            inputs: {
              fieldId: () => fieldId,
              harvestYear: () => harvestYear
            },
            onResult (nutrientMaps) {
              commit('updateInitialMapDataForField', {fieldId, maps: nutrientMaps, type: 'nutrientMap'})
            }
          })
      } catch (error) {
        if (error.response && error.response.status === 403) {
          commit('dataLoading/invalidate', 'nutrientMaps.forField', {root: true})
          commit('updateInitialMapDataForField', {fieldId, maps: [], type: 'nutrientMap'})
        } else {
          throw error
        }
      }
    },
    async loadAvailableApplicationMapsForField ({commit, rootState}, fieldId) {
      await smarterGet('/api/v2/map-management/fields/{fieldId}/application-map-infos/{harvestYear}', {
        id: 'applicationMapInfos.forField',
        expiry: 60, // TODO workaround for creation of applicationMaps in simpleApplicationmap and maizeSowing (displaying in ApplicationMapDetails)
        inputs: {
          fieldId: () => fieldId,
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
        },
        onResult (maps) {
          commit('updateInitialMapDataForField', {fieldId, maps, type: 'applicationMap'})
        }
      })
    },
    async loadSoilMapsForField ({commit, rootState}, fieldId) {
      await smarterGet('/api/v2/map-management/fields/{fieldId}/soil-map-infos/{harvestYear}', {
        id: 'soilMapInfos.forField',
        expiry: 0,
        inputs: {
          fieldId: () => fieldId,
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
        },
        onResult (maps) {
          commit('updateInitialMapDataForField', {fieldId, maps, type: 'soilMap'})
        }
      })
    },
    loadGeometryForField ({commit, rootGetters, rootState}) {
      const field = rootGetters['fieldRecordSystem/field']
      const fieldData = rootState.fieldRecordSystem.data.field[field.id]

      if (fieldData.wkt) {
        field.feature = {
          type: 'Feature',
          geometry: parse(fieldData.wkt),
          properties: {
            id: field.id
          }
        }
        commit('updateInitialMapDataForField', {fieldId: field.id, maps: [field], type: 'fieldBorder'})
      }
    },
    async loadZoneMapDetails ({commit}, id) {
      await smarterGet('/api/v2/zone-maps/{zoneMapId}/geometry', {
        id: 'zoneMaps.details',
        expiry: 10,
        inputs: {
          zoneMapId: () => id
        },
        onResult (details) {
          commit('updateMapDetails', {details: {id, ...details}, type: 'zoneMap'})
        }
      })
    },
    async loadNutrientMapDetails ({commit}, id) {
      await smarterGet('/api/v2/map-management/nutrient-map/{nutrientMapId}/details', {
        id: 'nutrientMap.details',
        expiry: 10,
        inputs: {
          nutrientMapId: () => id
        },
        onResult (mapDetails) {
          const {nutrientMap, details} = mapDetails
          if (details !== null) Object.assign(nutrientMap, details)
          commit('updateMapDetails', {details: {id, ...nutrientMap}, type: 'nutrientMap'})
        }
      })
    },
    // TODO split current endpoint into metadata and details endpoint
    async loadApplicationMapDetails ({commit}, applicationMapId) {
      if (!applicationMapId) return
      await smarterGet(
        '/api/v2/application-map/{applicationMapId}',
        {
          id: 'applicationMap.details',
          expiry: 10,
          inputs: {
            applicationMapId: () => applicationMapId
          },
          onResult (details) {
            if (details) {
              commit('updateMapDetails', {details, type: 'applicationMap'})
            }
          }
        }
      )
    },
    async loadSoilMapDetails ({state, commit}, id) {
      if (!id) return
      await smarterGet(
        '/api/v2/map-management/soil-map/{soilMapId}/details',
        {
          id: 'soilMap.details',
          expiry: 0,
          inputs: {
            soilMapId: () => id
          },
          onResult (details) {
            if (details) {
              commit('updateMapDetails', {details, type: 'soilMap'})
            }
          }
        }
      )
    },
    async loadInterpolationMapsForField ({commit, rootState}, fieldId) {
      await smarterGet('/api/v2/map-management/fields/{fieldId}/interpolation-maps/{harvestYear}', {
        id: 'interpolationMaps.forField',
        expiry: 0,
        inputs: {
          fieldId: () => fieldId,
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
        },
        onResult (maps) {
          if (maps && maps.length > 0) {
            commit('updateInitialMapDataForField', {fieldId, maps, type: 'soilMap'})
          }
        }
      })
    },
    async loadInterpolationMapsByOrgUnitId ({commit, rootState}, orgUnitId) {
      await smarterGet('/api/v2/map-management/orgunits/{orgUnitId}/interpolation-maps/{harvestYear}', {
        id: 'interpolationMaps.forOrgUnit',
        expiry: 0,
        inputs: {
          orgUnitId: () => orgUnitId,
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear
        },
        onResult ({interpolationMaps: maps, interpolationMapIdsByFieldId: mapIdsByFieldId}) {
          commit('updateInitialMapDataForOrgUnit', {maps, mapIdsByFieldId, type: 'soilMap'})
        }
      })
    },
    executeBatchDownload ({state, commit, dispatch}) {
      return dispatch('fieldRecordSystem/download/startDownload', {
        type: state.ui.selectionType,
        ids: state.mapIdsForDownload // state.ui.selection
      }, {root: true})
      .then(() => {
        commit('finishBatchOperation')
      })
    },
    checkScheduledRemovals ({state, commit, dispatch}) {
      const now = Date.now()
      const mapIds = Object.keys(pickBy(state.ui.scheduledRemovalLookup, timestamp => now >= timestamp))

      if (mapIds.length) {
        commit('unscheduleRemoval', mapIds)
        return dispatch('removeMaps', {mapIds})
      }
    },
    async removeMaps ({state, commit, getters}, {mapIds}) {
      if (!mapIds.length) return

      const {mapLookup} = getters

      const applicationMapIds = mapIds.filter(id => getMapType(state, id) === 'applicationMap')
      const zoneMapIds = mapIds.filter(id => getMapType(state, id) === 'zoneMap')
      const nutrientMapIds = mapIds.filter(id => getMapType(state, id) === 'nutrientMap')
      const soilMapIds = mapIds.filter(id => getMapType(state, id) === 'soilMap' && mapLookup[id].category !== 'InterpolatedSensorData')
      const sensorDataIds = mapIds.filter(id => getMapType(state, id) === 'soilMap' && mapLookup[id].category === 'InterpolatedSensorData')

      await Promise.all([
        applicationMapIds.length && axios.post('/api/v2/application-maps/delete', applicationMapIds),
        zoneMapIds.length && axios.post('/api/v2/zone-maps/delete', zoneMapIds),
        nutrientMapIds.length && axios.post('/api/v2/nutrient-maps/delete', nutrientMapIds),
        soilMapIds.length && axios.post('/api/v2/soil-maps/delete', soilMapIds),
        sensorDataIds.length && axios.post('/api/v2/soil-maps/sensor-data/delete', sensorDataIds)
      ])

      const {fieldIdsByMapId} = getters

      commit('removeMaps', {mapIds, fieldIdsByMapId})
    },
    async finishRename ({state, commit, getters}) {
      const mapId = state.ui.mapId
      const name = state.ui.newMapName
      const type = state.ui.selectionType
      const {mapLookup} = getters
      const map = mapLookup[mapId]

      let url
      switch (type) {
      case 'applicationMap':
        url = `/api/v2/application-maps/${mapId}/name?name=${name}`
        break
      case 'soilMap':
        if (map.category === 'InterpolatedSensorData') {
          url = `/api/v2/soil-maps/sensor-data/${mapId}/name?name=${name}`
        } else {
          url = `/api/v2/soil-maps/${mapId}/name?name=${name}`
        }
        break
      default:
        url = `/api/v2/zone-maps/${mapId}/name?name=${name}`
        break
      }

      try {
        await axios.put(url)

        commit('finishRename')
      } catch (error) {
        commit('cancelRename')
        throw error
      }
    },
    cancelRename ({commit}) {
      commit('cancelRename')
    },
    async startHeterogenityChange ({state, commit, dispatch}, mapId) {
      const type = getMapType(state, mapId)

      if (type !== 'zoneMap') throw new Error(`heterogenity only exists for zoneMaps, map was '${type}'`)

      await dispatch('loadZoneMapDetails', mapId)

      commit('startHeterogenityChange', mapId)
    },
    async finishHeterogenityChange ({state, commit}) {
      const mapId = state.ui.mapId
      const heterogenity = state.ui.heterogenity
      const type = getMapType(state, mapId)

      if (type !== 'zoneMap' || heterogenity === undefined) {
        throw new Error(`cannot update heterogenity for map of type '${type}' with value '${heterogenity}'`)
      }

      try {
        await axios.put(`/api/v2/zone-maps/${mapId}/heterogenity?heterogenity=${heterogenity}`)
        commit('finishHeterogenityChange')
      } catch (error) {
        commit('cancelHeterogenityChange')
        throw error
      }
    },
    cancelHeterogenityChange ({commit}) {
      commit('cancelHeterogenityChange')
    },
    async activateZoneMap ({state, commit}, {zoneMapId, isDemo}) {
      const isActive = state.data.zoneMap.mapLookup[zoneMapId].active

      if (isActive) throw new Error(`zone map ${zoneMapId} is already activated`)

      commit('setZoneMapActivation', {zoneMapId, isActive: true, isDemo})

      try {
        await axios.put(`/api/v2/zone-maps/${zoneMapId}/is-active`, {
          isDemo
        })
      } catch (error) {
        commit('setZoneMapActivation', {zoneMapId, isActive, isDemo: false})
        throw error
      }
    },
    async deactivateZoneMap ({state, commit}, {zoneMapId}) {
      const isActive = state.data.zoneMap.mapLookup[zoneMapId].active
      const isDemo = state.data.zoneMap.mapLookup[zoneMapId].isDemo

      if (!isActive) throw new Error(`zone map ${zoneMapId} is not activated`)

      commit('setZoneMapActivation', {zoneMapId, isActive: false, isDemo: false})

      try {
        await axios.delete(`/api/v2/zone-maps/${zoneMapId}/is-active`)
      } catch (error) {
        commit('setZoneMapActivation', {zoneMapId, isActive, isDemo})
        throw error
      }
    },
    async saveModifiedZoneMap ({state, dispatch, getters}, {newName, geometriesByZoneId, mapId}) {
      const currentFieldId = getters.fieldIdsByMapId[mapId]
      try {
        await axios.post(`/api/v2/zone-maps/${mapId}/geometry`, {
          newName,
          geometriesByZoneId
        }).then(() => dispatch('loadAvailableZoneMaps', currentFieldId))
      } catch (error) {
        console.error(error)
      }
    },
    invalidateLoadAllMaps ({commit}) {
      commit('dataLoading/invalidate', 'zoneMaps.forField', {root: true})
      commit('dataLoading/invalidate', 'nutrientMaps.forField', {root: true})
      commit('dataLoading/invalidate', 'applicationMapInfos.forField', {root: true})
      commit('dataLoading/invalidate', 'soilMapInfos.forField', {root: true})
      commit('dataLoading/invalidate', 'zoneMapInfos.forOrgUnit', {root: true})
      commit('dataLoading/invalidate', 'nutrientMaps.forOrgUnit', {root: true})
      commit('dataLoading/invalidate', 'applicationMapInfos.forOrgUnit', {root: true})
      commit('dataLoading/invalidate', 'soilMapInfos.forOrgUnit', {root: true})
    }
  },
  modules: {
    zoneMapImport,
    soilMapImport,
    zoneMapAdditionalValues
  }
}
