import {cloneDeep} from 'lodash'
import axios from 'axios'

import {makeSetters} from '@helpers/vuex/mutations'
import {smarterGet} from '@helpers/vuex/data-loading'

export default {
  namespaced: true,
  state: {
    values: null, // {[zoneId]: pwc: value, acreRatio: value, waterAvailability: value}
    originalValues: null
  },
  mutations: {
    ...makeSetters([
      'values'
    ]),
    startEditing (state, {values}) {
      state.values = values
      state.originalValues = cloneDeep(values)
    }
  },
  actions: {
    setupEditValues ({commit, rootState}, zoneMapId) {
      smarterGet('/api/v2/zone-maps/{zoneMapId}/additional-values', {
        id: 'frs.mapManagement.additionalValues.load',
        inputs: {
          zoneMapId: () => zoneMapId
        },
        onResult (data) {
          const values = {}

          data.zoneValues.forEach(zoneData => {
            values[zoneData.zoneId] = {}

            for (const key of ['waterAvailability', 'acreRatio', 'pwc']) {
              values[zoneData.zoneId][key] = zoneData[key]
            }
          })

          commit('startEditing', {values})
        }
      })
    },
    copyFrom ({rootState}, {sourceZoneMapId, targetZoneMapId}) {
      return axios.put(`/api/v2/zone-maps/${targetZoneMapId}/additional-values/from/${sourceZoneMapId}`)
    },
    save ({state, dispatch, rootState}, mapId) {
      const dto = {
        zoneValues: Object.keys(state.values).map(zoneId => ({
          zoneId,
          pwc: state.values[zoneId].pwc,
          acreRatio: state.values[zoneId].acreRatio,
          waterAvailability: state.values[zoneId].waterAvailability
        }))
      }
      axios.put(`/api/v2/zone-maps/${mapId}/additional-values`, dto)
    }
  }
}
